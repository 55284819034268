import React, { Component } from "react"
import SEO from "../components/seo"
import "../styles/style.css"
import Layout from "../components/layout"

class ThankYou extends Component {
    render () {

        return (
            <>
            <SEO title="Thankyou" description="Professional web accessibility services" />
            <Layout>
                <div className="splash light">
                    <h2>Thank you for your message. We will be in touch soon.</h2>
                </div>
            </Layout>
            </>
        )
    }
}

export default ThankYou